// Generated by Framer (575ceb6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const enabledGestures = {j24vTLQAM: {hover: true}};

const cycleOrder = ["j24vTLQAM"];

const variantClassNames = {j24vTLQAM: "framer-v-1vm9dis"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; icon?: string; newTab?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "j24vTLQAM", link: TDI2IyCsW, icon: Y0mjt8NVo = "ArrowRight", newTab: Gs98L82lh = false, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "j24vTLQAM", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qLCho", classNames)} style={{display: "contents"}}>
<Link href={TDI2IyCsW} openInNewTab={Gs98L82lh}><motion.a {...restProps} className={`${cx("framer-1vm9dis", className)} framer-1g1p0me`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"j24vTLQAM"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(45, 33, 53, 0.7))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(10px)", backgroundColor: "var(--token-81ab93ff-d515-4aca-b63b-24f83b57a3ab, rgba(31, 23, 34, 0.5))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, WebkitBackdropFilter: "blur(10px)", ...style}} transition={transition} variants={{"j24vTLQAM-hover": {"--border-color": "var(--token-9ed7888c-aeb5-48dd-aee6-b885d99628b4, rgb(64, 52, 72))", backgroundColor: "rgba(44, 33, 51, 0.5)"}}} {...addPropertyOverrides({"j24vTLQAM-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1pxou53-container"} layoutDependency={layoutDependency} layoutId={"sUx8iHmDr-container"} transition={transition}><Phosphor color={"var(--token-efbc6c56-80fe-4475-9b37-9684d6e92632, rgb(239, 237, 253)) /* {\"name\":\"icon/default\"} */"} height={"100%"} iconSearch={"House"} iconSelection={Y0mjt8NVo} id={"sUx8iHmDr"} layoutId={"sUx8iHmDr"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qLCho [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qLCho .framer-1g1p0me { display: block; }", ".framer-qLCho .framer-1vm9dis { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 6px 6px 6px 6px; position: relative; text-decoration: none; width: min-content; }", ".framer-qLCho .framer-1pxou53-container { flex: none; height: 16px; position: relative; width: 16px; }", ".framer-qLCho .framer-v-1vm9dis .framer-1vm9dis { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qLCho .framer-1vm9dis { gap: 0px; } .framer-qLCho .framer-1vm9dis > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-qLCho .framer-1vm9dis > :first-child { margin-top: 0px; } .framer-qLCho .framer-1vm9dis > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 28
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"NaGR4GuVW":{"layout":["auto","auto"]}}}
 * @framerVariables {"TDI2IyCsW":"link","Y0mjt8NVo":"icon","Gs98L82lh":"newTab"}
 */
const Framerdu1REMnq8: React.ComponentType<Props> = withCSS(Component, css, "framer-qLCho") as typeof Component;
export default Framerdu1REMnq8;

Framerdu1REMnq8.displayName = "Button Icon";

Framerdu1REMnq8.defaultProps = {height: 28, width: 28};

addPropertyControls(Framerdu1REMnq8, {TDI2IyCsW: {title: "Link", type: ControlType.Link}, Y0mjt8NVo: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "ArrowRight", hidden: undefined, title: "Icon"}, Gs98L82lh: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(Framerdu1REMnq8, [...PhosphorFonts])